import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import Link from 'next/link';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTwitter } from '@fortawesome/free-brands-svg-icons/faTwitter';
import classNames from 'classnames';
import { faFacebook } from '@fortawesome/free-brands-svg-icons/faFacebook';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons/faLinkedin';
import { useEmail } from '../components/hooks/useEmail';
import style from './Footer.module.scss';

export const FooterLink: React.FC<{ children: JSX.Element | string; to: string }> = ({ children, to }) => {
  return (
    <li className="py-2">
      <Link prefetch={false} href={to} passHref>
        {children}
      </Link>
    </li>
  );
};

export const FooterLinks: React.FC = () => {
  return (
    <ul className={classNames('list-unstyled', style.links)}>
      <FooterLink to="/pricing">
        <FormattedMessage id="web.nav.pricing" defaultMessage="Pricing" />
      </FooterLink>
      <FooterLink to="/privacy-policy">
        <FormattedMessage id="web.footer.privacy-policy" defaultMessage="Privacy Policy" />
      </FooterLink>
      <FooterLink to="/refund-policy">
        <FormattedMessage id="web.footer.refund-policy" defaultMessage="Refund Policy" />
      </FooterLink>
      <FooterLink to="/terms-of-service">
        <FormattedMessage id="web.footer.terms-of-service" defaultMessage="Terms of Service" />
      </FooterLink>
      <FooterLink to="/imprint">
        <FormattedMessage id="web.footer.imprint" defaultMessage="Imprint" />
      </FooterLink>
      <FooterLink to="/blog">
        <FormattedMessage id="web.nav.blog" defaultMessage="Blog" />
      </FooterLink>
      <FooterLink to="/press-kit">
        <FormattedMessage id="web.nav.press-kit" defaultMessage="Press Kit" />
      </FooterLink>
    </ul>
  );
};
export const FooterCommercialLinks: React.FC = () => {
  const intl = useIntl();
  return (
    <ul className={classNames('list-unstyled', style.links)}>
      <FooterLink to="/business/event-agency">
        {intl.formatMessage({
          id: 'web.commercial.event-agency.title',
          defaultMessage: 'Event Agency'
        })}
      </FooterLink>
      <FooterLink to="/business/wedding-host-djs">
        {intl.formatMessage({
          id: 'web.commercial.djs.title',
          defaultMessage: 'Wedding Host / DJ'
        })}
      </FooterLink>
      <FooterLink to="/business/hr-coach">
        {intl.formatMessage({
          id: 'web.commercial.training.title',
          defaultMessage: 'HR / Coach'
        })}
      </FooterLink>
      <FooterLink to="/home/individual">
        {intl.formatMessage({
          id: 'web.commercial.individual.title',
          defaultMessage: 'Individual'
        })}
      </FooterLink>
      <FooterLink to="/weddings">
        {intl.formatMessage({
          id: 'web.commercial.weddings.title',
          defaultMessage: 'For Weddings'
        })}
      </FooterLink>
      <FooterLink to="/questions/categories">
        {intl.formatMessage({
          id: 'web.footer.questions_answers',
          defaultMessage: 'Questions & Answers'
        })}
      </FooterLink>
    </ul>
  );
};

export const Footer: React.FC = () => {
  const intl = useIntl();
  const email = useEmail();
  return (
    <footer className="container py-5">
      <div className="d-flex justify-content-around flex-column flex-md-row">
        <div className="d-flex justify-content-around flex-grow-1">
          <div>
            <FooterLinks />
          </div>
          <div>
            <FooterCommercialLinks />
          </div>
        </div>
        <div className="overflow-hidden">
          <div className={classNames('d-flex flex-row justify-content-center mt-5 mt-md-0', style.socials)}>
            <a className={style.social} href="https://fb.me/quizadoapp">
              <FontAwesomeIcon icon={faFacebook} />
            </a>
            <a className={style.social} href="https://twitter.com/quizadoapp">
              <FontAwesomeIcon icon={faTwitter} />
            </a>
            <a className={style.social} href="https://www.linkedin.com/company/quizado/">
              <FontAwesomeIcon icon={faLinkedin} />
            </a>
          </div>
          <div className="d-flex flex-column text-heading text-center text-md-right mt-5">
            <a href={`mailto:${email}`}>{email}</a>
          </div>
        </div>
      </div>
      <div className={style.copyright}>
        {intl.formatMessage(
          {
            id: 'web.footer.rights',
            defaultMessage: '©{date} Quizado.com'
          },
          { date: new Date().getFullYear() }
        )}
        <br />
        {intl.formatMessage({
          id: 'web.footer.not-associated',
          defaultMessage: 'Quizado is not associated with Family Feud, Familien-Duell and Familiada'
        })}
      </div>
    </footer>
  );
};
