import React from 'react';
import { DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import { useRouter } from 'next/router';
import { DropdownLangItem, LangItemFlag } from './DropdownLangItem';
import style from './LanguagePicker.module.scss';

export const LanguagePicker: React.FC<{ onSelected?: () => void }> = React.memo(({ onSelected }) => {
  const { locale, locales } = useRouter();
  return (
    <>
      {locales && locales.length > 1 && (
        <UncontrolledDropdown>
          <DropdownToggle size="sm" color="secondary" className={style.langPickerPreview}>
            <LangItemFlag lang={locale} />
          </DropdownToggle>
          <DropdownMenu right className={style.langPicker}>
            {locales.map((lang: string) => (
              <DropdownLangItem key={lang} lang={lang} active={lang === locale} onClick={onSelected} />
            ))}
          </DropdownMenu>
        </UncontrolledDropdown>
      )}
    </>
  );
});
