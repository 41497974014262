import React, { useState } from 'react';
import { DropdownItem, DropdownMenu, DropdownToggle, Nav, NavItem, NavLink, UncontrolledDropdown } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import Link from 'next/link';
import { GenericNavbar } from './GenericNavbar';
import { NavbarTestNowButton } from './NavbarTestNowButton';

export const NavbarComponent: React.FC<{ dark?: boolean }> = React.memo(({ dark }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <GenericNavbar
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      dark={dark}
      additionalLeftNavItems={
        <>
          <NavItem className="px-2 text-heading">
            <Link prefetch={false} href="/features" passHref legacyBehavior>
              <NavLink>
                <FormattedMessage id="web.nav.features" defaultMessage="Features" />
              </NavLink>
            </Link>
          </NavItem>
          <UncontrolledDropdown nav inNavbar>
            <DropdownToggle nav caret className="px-2 text-heading">
              <FormattedMessage id="web.nav.games" defaultMessage="Games" />
            </DropdownToggle>
            <DropdownMenu left>
              <DropdownItem>
                <Link prefetch={false} href="/games/family-feud" passHref legacyBehavior>
                  <NavLink className="text-heading">
                    <FormattedMessage id="web.games.family-feud" defaultMessage="Family Feud" />
                  </NavLink>
                </Link>
              </DropdownItem>
              <DropdownItem>
                <Link prefetch={false} href="/games/name-that-tune" passHref legacyBehavior>
                  <NavLink className="text-heading">
                    <FormattedMessage id="web.games.name-that-tune" defaultMessage="Name That Tune" />
                  </NavLink>
                </Link>
              </DropdownItem>
              <DropdownItem>
                <Link prefetch={false} href="/games/jeopardy" passHref legacyBehavior>
                  <NavLink className="text-heading">
                    <FormattedMessage id="web.games.jeopardy" defaultMessage="Jeopardy!" />
                  </NavLink>
                </Link>
              </DropdownItem>
              <DropdownItem>
                <Link prefetch={false} href="/games/wheel-of-fortune" passHref legacyBehavior>
                  <NavLink className="text-heading">
                    <FormattedMessage id="web.games.wheel-of-fortune" defaultMessage="Wheel Of Fortune" />
                  </NavLink>
                </Link>
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
          <NavItem className="px-2 text-heading">
            <Link prefetch={false} href="/pricing" passHref legacyBehavior>
              <NavLink>
                <FormattedMessage id="web.nav.pricing" defaultMessage="Pricing" />
              </NavLink>
            </Link>
          </NavItem>
          <NavItem className="px-2 text-heading">
            <Link prefetch={false} href="/blog" passHref legacyBehavior>
              <NavLink>
                <FormattedMessage id="web.nav.blog" defaultMessage="Blog" />
              </NavLink>
            </Link>
          </NavItem>
          <NavItem className="px-2 text-heading">
            <NavLink href="https://support.quizado.com">
              <FormattedMessage id="web.faq.title" defaultMessage="FAQ" />
            </NavLink>
          </NavItem>
        </>
      }
      rightContent={
        <Nav navbar className="order-lg-12 ml-auto ord">
          <NavbarTestNowButton dark={dark} />
        </Nav>
      }
    />
  );
});
