import React from 'react';
import { NavbarBrand } from 'reactstrap';
import { defineMessages, useIntl } from 'react-intl';
import Link from 'next/link';
import { nextAsset } from '../components/nextAsset';

const { logoAlt } = defineMessages({
  logoAlt: {
    id: 'web.nav.logo-alt',
    defaultMessage: 'Family Feud simulator - Quizado.com'
  }
});

const BrandLink: React.FC<React.PropsWithChildren<any>> = ({ children }) => (
  <Link prefetch={false} href="/" passHref>
    {children}
  </Link>
);

export const GenericNavbarBrand: React.FC<{
  dark?: boolean;
}> = ({ dark }) => {
  const intl = useIntl();

  return (
    <NavbarBrand className="ml-auto ml-sm-auto mr-auto" tag={BrandLink}>
      <img
        className="navbar-brand-regular"
        src={nextAsset(`logos/logo-full-${dark ? 'dark' : 'light'}.svg`)}
        alt={intl.formatMessage(logoAlt)}
        style={{ height: 40, margin: 10 }}
      />
    </NavbarBrand>
  );
};
