import React from 'react';
import { DropdownItem } from 'reactstrap';
import { useRouter } from 'next/router';
import Link from 'next/link';

export const LangItemFlag: React.FC<{
  lang: string | undefined;
}> = ({ lang }) => {
  let flagName: string | undefined;
  switch (lang) {
    case 'en':
      flagName = 'gb';
      break;
    default:
      flagName = lang;
      break;
  }
  return <img src={`/statics/images/flags/4x3/${flagName}.svg`} alt="" />;
};

export const DropdownLangItem: React.FC<{
  lang: string | undefined;
  active: boolean;
  onClick: React.HTMLProps<HTMLAnchorElement>['onClick'];
}> = React.memo(({ lang, active, onClick }) => {
  const router = useRouter();
  const { pathname, query } = router;

  return (
    <Link prefetch={false} href={{ pathname, query }} passHref locale={lang} legacyBehavior>
      <DropdownItem active={active} onClick={onClick}>
        <LangItemFlag lang={lang} /> {(lang ?? '').toUpperCase()}
      </DropdownItem>
    </Link>
  );
});
